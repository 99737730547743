import 'popper.js';
import 'bootstrap';
import Swiper from 'swiper';
import lozad from 'lozad';
import noUiSlider from 'nouislider';

import './yii2-assets/yii';
import './yii2-assets/yii.validation';
import './yii2-assets/yii.activeForm';
import './yii2-assets/jquery.pjax.js';

require("expose-loader?jQuery!jquery");

require.context('../svg', true, /\.svg$/); //Загрузка всех svg спрайтов
;(function(window, document) {
  'use strict';
  var file = '/img/sprite.svg', // путь к файлу спрайта на сервере
      revision = 21;            // версия спрайта
  if (!document.createElementNS || !document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect) return true;
  var isLocalStorage = 'localStorage' in window && window['localStorage'] !== null,
    request,
    data,
    insertIT = function() {
      document.body.insertAdjacentHTML('afterbegin', data);
    },
    insert = function() {
      if (document.body) insertIT();
      else document.addEventListener('DOMContentLoaded', insertIT);
    };
  if (isLocalStorage && localStorage.getItem('inlineSVGrev') == revision) {
    data = localStorage.getItem('inlineSVGdata');
    if (data) {
      insert();
      return true;
    }
  }
  try {
    request = new XMLHttpRequest();
    request.open('GET', file, true);
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        data = request.responseText;
        insert();
        if (isLocalStorage) {
          localStorage.setItem('inlineSVGdata', data);
          localStorage.setItem('inlineSVGrev', revision);
        }
      }
    }
    request.send();
  } catch (e) {}
}(window, document));

$(function () {
    $('[data-toggle="popover"]').popover()
})

var breakpoints = {
    767: {
        slidesPerView: 1
    },
    870: {
        slidesPerView: 3
    },
    1080: {
        slidesPerView: 4
    },
    1310: {
        slidesPerView: 5
    },
    1520: {
        slidesPerView: 6
    },
    1725: {
        slidesPerView: 7
    }
};
var popularSwiper = new Swiper ('.swiper-container.popular', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    loop: false,
    autoplay: false,
    slidesPerView: 8,
    breakpoints: breakpoints,
    spaceBetween: 20,
    roundLengths : true,

    // And if we need scrollbar
    scrollbar: {
      el: '.swiper-scrollbar.popular',
    },
    navigation: {
      nextEl: '.swiper-popular-next',
      prevEl: '.swiper-popular-prev',
    } 
});
var searchlightSwiper = new Swiper ('.swiper-container.searchlight', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    loop: false,
    autoplay: false,
    slidesPerView: 8,
    breakpoints: breakpoints,
    spaceBetween: 20,
    roundLengths : true,

    // And if we need scrollbar
    scrollbar: {
      	el: '.swiper-scrollbar.searchlight',
    },
    navigation: {
      nextEl: '.swiper-searchlight-next',
      prevEl: '.swiper-searchlight-prev',
    }   
});
var mgaSwiper = new Swiper ('.swiper-container.mga', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    loop: false,
    autoplay: false,
    slidesPerView: 8,
    breakpoints: breakpoints,
    spaceBetween: 20,
    roundLengths : true,

    // And if we need scrollbar
    scrollbar: {
      	el: '.swiper-scrollbar.mga',
    },
    navigation: {
      nextEl: '.swiper-mga-next',
      prevEl: '.swiper-mga-prev',
    }   
});
var navLight = new Swiper ('.swiper-container.navlight', {
    // Optional parameters
    direction: 'horizontal',
    grabCursor: true,
    loop: false,
    autoplay: false,
    slidesPerView: 8,
    breakpoints: breakpoints,
    spaceBetween: 20,
    roundLengths : true,

    // And if we need scrollbar
    scrollbar: {
        el: '.swiper-scrollbar.navlight',
    },
    navigation: {
      nextEl: '.swiper-navlight-next',
      prevEl: '.swiper-navlight-prev',
    }   
});

var productionSwiper = undefined;
function initProductionSwiper(){
    var screenWidth = $(window).width();
    if(screenWidth < 974 && productionSwiper == undefined) {
        productionSwiper = new Swiper('.row.swiper', { 
            direction: 'horizontal',
            wrapperClass: 'production-list',
            slideClass: 'card-container',
            slidesPerView: 'auto',
            roundLengths : true,
            breakpoints: {
                462:{
                    centeredSlides: false,
                }
            }
        });
    }
    else if (screenWidth >= 975 && productionSwiper != undefined) {
        $(productionSwiper).each(function(index, el) {
          el.destroy();
        });
        productionSwiper = undefined;      
    }   
}

initProductionSwiper();
$(window).on('resize', function(){
    initProductionSwiper();        
});

const observer = lozad('.load-after', {

});
observer.observe();

$('#top-form-search').on('input',function(e){
    if (($('#top-form-search input').val().length) > 1) {
        $('#top-form-search .search-result').addClass("active");
    } else {
        $('#top-form-search .search-result').removeClass("active");
    }
});

$("[id^=carousel-selector-]").click( function(){
    var id = this.id.substr(this.id.lastIndexOf("-") + 1);
    var id = parseInt(id);
    $("#carousel-product").carousel(id);
});
$("#carousel-product").on("slid.bs.carousel", function (e) {
    var id = $(".item.active").data("slide-number");
    $("#carousel-text").html($("#slide-content-" + id).html());
}); 

$("[id^=blueprint-selector-]").click( function(){
    var id = this.id.substr(this.id.lastIndexOf("-") + 1);
    var id = parseInt(id);
    $("#carousel-blueprint").carousel(id);
});
$("#carousel-blueprint").on("slid.bs.carousel", function (e) {
    var id = $(".item.active").data("slide-number");
    $("#carousel-text").html($("#slide-content-" + id).html());
}); 


$("#getprice-toggle").on("click", function (e) {
    $("#getprice").toggleClass("show");
}); 

$("table tr.expand").on( "click", function(e) {
    e.preventDefault();
    $(this).closest("table").toggleClass("table-striped").toggleClass("table-striped");
    $(this).closest("table").find("tr.hide").show(200).toggleClass("expand").toggleClass("hide");
    $(this).hide();
}); 

var voltageSlider = document.getElementById("voltageSlider");
if (voltageSlider){
    noUiSlider.create(voltageSlider, {
        start: [1, 250],
        tooltips: [true, true ],
        range: {
            'min': 1,
            'max': 250
        },
        pips: {
            mode: "range",
            density: 6
        },
        format: {
            to: function ( value ) {
                return Math.round(value);
            },
            from: function ( value ) {
                return Math.round(value);
            }
        }    
    });
}
var powerSlider = document.getElementById("powerSlider");
if (powerSlider){
    noUiSlider.create(powerSlider, {
        start: [0, 7000],
        tooltips: [true, true ],
        range: {
            "min": 0,
            "1.75%": [3],
            "3.5%": [4],
            "5.25%": [5],
            "7%": [6],
            "8.75%": [7],
            "10.5%": [8],
            "12.25%": [9],
            "14%": [10],
            "15.75%": [11],
            "17.5%": [13],
            "19.25%": [14],
            "21%": [15],
            "22.75%": [16],
            "24.5%": [18],
            "26.25%": [20],
            "28%": [21],
            "29.75%": [22],
            "31.5%": [24],
            "33.25%": [25],
            "35%": [26],
            "36.75%": [28],
            "38.5%": [30],
            "40.25%": [35],
            "42%": [36],
            "43.75%": [39],
            "45.5%": [40],
            "47.25%": [49],
            "49%": [50],
            "50.75%": [54],
            "52.5%": [55]
            ,"54.25%": [60],
            "56%": [65],
            "57.75%": [70],
            "59.5%": [75],
            "61.25%": [80],
            "63%": [95],
            "64.75%": [100],
            "66.5%": [120],
            "68.25%": [150],
            "70%": [160],
            "71.75%": [200],
            "73.5%": [230],
            "75.25%": [250],
            "77%": [300],
            "78.75%": [400],
            "80.5%": [500],
            "82.25%": [565],
            "84%": [600],
            "85.75%": [650],
            "87.5%": [750],
            "89.25%": [1000],
            "91%": [1500],
            "92.75%": [1600],
            "94.5%": [2000],
            "96.25%": [3000],
            "98%": [5000],
            "max": 7000
        },
        snap: true,
        pips: {
            mode: "values",
            values: [0,3,4,5,6,7,8,9,10,11,13,14,15,16,18,20,21,22,24,25,26,28,30,35,36,39,40,49,50,54,55,60,65,70,75,80,95,100,120,150,160,200,230,250,300,400,500,565,600,650,750,1000,1500,1600,2000,3000,5000,7000], stepped: true, 
            density: 6
        },
        format: {
            to: function ( value ) {
                return Math.round(value);
            },
            from: function ( value ) {
                return Math.round(value);
            }
        }
    });
}